
import { Component, Vue } from 'vue-property-decorator'
import { Page } from '@/types/common.d'
import { Warehousing } from '@/types/material.d'

@Component({ name: 'Warehousing' })
export default class extends Vue {
  page = 1
  size = 10
  total = 0
  loading = false
  tableData: Warehousing[] = []

  dateRange = []
  searchForm = {
    projectName: '', // 项目名称
    storageNum: '', // 入库单号
    startDate: '', // 入库日期
    endDate: '' // 入库日期
  }

  created () {
    this.loadData()
  }

  loadData () {
    this.loading = true
    this.$axios.get<Page<Warehousing>>(this.$apis.material.selectStorageByPage, {
      page: this.page,
      size: this.size,
      ...this.searchForm
    }).then(res => {
      this.tableData = res.list
      this.total = res.total
    }).finally(() => {
      this.loading = false
    })
  }

  dateRangeChange (date: any) {
    if (date) {
      this.searchForm.startDate = date[0]
      this.searchForm.endDate = date[1]
    } else {
      this.searchForm.startDate = ''
      this.searchForm.endDate = ''
    }
  }

  onSearch () {
    this.page = 1
    this.loadData()
  }

  // 下载
  onDownLoad (id: string) {
    const loading = this.$loading({
      lock: true,
      text: '下载中...',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    })
    this.$axios.downloadFile(this.$apis.material.inExportExcel, {
      id: id
    }).finally(() => {
      loading.close()
    })
  }
}
